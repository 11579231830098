import clsx from 'clsx';
import { useState } from 'react';
import styles from './icons.module.scss';
import { useFundraiserInvitesClient } from '../../data/fundraiser-invites-client';
import { Icon } from './icon';
import { useFundraiserPermissions, useFundraisersClient } from '../../data/fundraiser-service';
import { useAuth } from '../../hooks/use-auth';
import { useParticipantsClient } from '../../data/participant-service';
import { useProfilesClient } from '../../data/profile-service';
import { SimpleButton } from '../buttons/simple-button';

export const ShareFundraiserIcon = ({
  fundraiserId,
}: {
  fundraiserId: string;  
}) => {
  const fundraisersClient = useFundraisersClient();
  const profilesClient = useProfilesClient();
  const participantsClient = useParticipantsClient();
  const auth = useAuth();

  const onClick = async () => {
    try {
      const fundraiser = await fundraisersClient.get(fundraiserId);
      const profiles = await profilesClient.find({
        accountId: auth.value?.accountId,
        limit: 1,
      });

      const shareUrl = new URL(`/fundraisers/${fundraiserId}`, window.origin);

      const participant = await (async () => {
        if (profiles.rows.length === 0) return null;
        
        const participants = await participantsClient.find({
          fundraiserId,
          profileId: profiles.rows[0].id,
        });
        if (participants.rows.length === 0) return null;
        return participants.rows[0];
      })();

      if (participant !== null) {
        shareUrl.searchParams.set('participantId', participant.id);
      }

      const signature = (() => {
        if (profiles.rows.length === 0) return 'QwikRaise';
        return profiles.rows[0].name;
      })();

      const share = {
        text: `Please support our ${fundraiser.title} fundraiser today! Click on the link below to donate now.\n${shareUrl}\n\nThank You - ${signature}`,
      };
      if (navigator.canShare && navigator.canShare(share)) {
        navigator.share(share);
      }
      else if (navigator.clipboard) {
        navigator.clipboard.writeText(share.text);
        alert('Copied to Clipboard');
      }
      else alert('Copy this link to share: ' + share.text);
    }
    catch (err) {
      alert(err);
    }
  }

  return <div onClick={onClick}>
    <SimpleButton>Share</SimpleButton>
  </div>
}
