export enum ErrorCode {
  BadRequest = "bad-request",
  Unauthorized = 'unauthorized',
  Forbidden = 'forbidden',
  NotFound = 'not-found',
  Conflict = 'conflict',
  InternalError = 'internal-error',
}

export enum IssueCode {
  InviteExpired = 'invite-expired',
  RequiredValue = 'required-value',
  TooManyValues = 'too-many-values',
  MissingValues = 'missing-values',
  InvalidField = 'invalid-field',
  InvalidValue = 'invalid-value',
  InvalidType = 'invalid-type',
  InvalidArgument = 'invalid-argument',
  PaymentDeclined = 'payment-declined',
}

export type Issue = {
  code: IssueCode;
  field: string;
  invalidValue?: string;
  expectedFormat?: string;
  invalidArgument?: string;
}

export class AppError extends Error {
  constructor(
    public code: ErrorCode,
  ) {
    super('app-error:' + code);
  }
}

export class NotFoundError extends Error {
  constructor(
    public resourceType: string,
    public queryParams?: any,
  ) {
    super('not-found');
  }
}

export class BadRequestError extends AppError {
  constructor(
    public issues: Issue[]
  ) {
    super(ErrorCode.BadRequest);
  }
}

export class UnauthorizedError extends AppError {
  constructor(
    public issue: string,
  ) {
    super(ErrorCode.Unauthorized);
  }
}

export class ForbiddenError extends AppError {
  constructor(
    public issue: string,
  ) { 
    super(ErrorCode.Forbidden);
  }
}

export class ConflictError extends AppError {
  constructor(
    public conflictingField: string,
  ) {
    super(ErrorCode.Conflict);
  }
}

export class InternalError extends AppError {
  constructor(
    public error: Error,
  ) {
    super(ErrorCode.InternalError);
  }
}