import React, {useState} from 'react';
import ReactDOM from 'react-dom';
import {Stripe, StripeElements, StripeElementsOptionsMode, loadStripe} from '@stripe/stripe-js';
import {
  PaymentElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { useMapping } from '../../hooks/use-mapping';
import styles from './forms.module.scss';
import clsx from 'clsx';
import { SimpleButton } from '../buttons/simple-button';
import useHttp from '../../hooks/use-http';
import { ToggleInput } from '../inputs/toggle-input';
import { DonationInput, useDonationsClient } from '../../data/donation-service';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { PriceLabel } from '../labels/price-label';
import * as yup from 'yup';
import { ErrorLabel } from '../labels/error-label';

// NOTE: Amounts are in cents.
const donationAmounts: (number | 'other')[] = [
  3500,
  5000,
  10000,
  25000,
  50000,
  'other',
];

export const AddDonationForm = ({
  fundraiserId,
  participantId,
  onClose,
}: {
  fundraiserId: string;
  participantId?: string;
  onClose: () => void;
}) => {
  const nav = useNavigate();
  const donationsClient = useDonationsClient();
  const [ queryParams ] = useSearchParams();

  const [ submitting, setSubmitting ] = useState(false);
  const [ error, setError ] = useState<Error | null>(null);
  const [ anonymous, setAnonymous ] = useState(false);
  const [ selectedAmount, setSelectedAmount ] = useState<number | 'other' | null>(null);

  const _onSubmit = async (event: any) => {
    event.preventDefault();
    const targets = event.target.elements;
    if (!targets) return;

    const donorName = targets.donorName?.value;
    const message = targets.message?.value;
    let amount = (() => {
      if (selectedAmount === null) throw new Error('Please select a donation amount.');
      if (selectedAmount !== 'other') return selectedAmount;

      return targets.otherAmount?.value * 100; // Convert to cents.
    })();

    const input: DonationInput = yup.object().shape({
      fundraiserId: yup.string().uuid().required(),
      participantId: yup.string().uuid(),
      donorName: yup.string(),
      amount: yup.number().required(),
      message: yup.string(),
      paymentMethod: yup.object({
        card: yup.object({
          number: yup.string().required(),
          expirationDate: yup.string().required(),
          cvc: yup.string().required(),
        }),
      }),
      billingAddress: yup.object({
        firstName: yup.string().required(),
        lastName: yup.string().required(),
        companyName: yup.string(),
        addressLine1: yup.string().required(),
        addressLine2: yup.string(),
        city: yup.string().required(),
        state: yup.string().required(),
        postalCode: yup.string().required(),
        phoneNumber: yup.string().required(),
        email: yup.string().email().required(),
      }),
    }).strict(true).validateSync({
      amount,
      fundraiserId,
      participantId,
      donorName,
      message,
      paymentMethod: {
        card: {
          number: targets['card.number'].value,
          expirationDate: targets['expr.month'].value + '/' + targets['expr.year'].value,
          cvc: targets['card.cvc'].value,
        }
      },
      billingAddress: {
        firstName: targets['billing.firstName'].value,
        lastName: targets['billing.lastName'].value,
        companyName: targets['billing.companyName'].value,
        addressLine1: targets['billing.addressLine1'].value,
        addressLine2: targets['billing.addressLine2'].value,
        city: targets['billing.city'].value,
        state: targets['billing.state'].value,
        postalCode: targets['billing.postalCode'].value,
        phoneNumber: targets['billing.phone'].value,
        email: targets['billing.email'].value,
      },
    });

    // Create the PaymentIntent and obtain clientSecret from your server endpoint
    const {
      donation,
      // secret,
    } = await donationsClient.create({
      ...input,
      // amount,
      // donorName,
      participantId: queryParams.get('participantId') || participantId || undefined,
    })

    nav('?thanks=true', { replace: true, 'unstable_flushSync': true });
  };

  const onSubmit = async (event: any) => {
    setSubmitting(true);
    setError(null);
    try {
      await _onSubmit(event);
    }
    catch (err: any) {
      setError(err);
    }
    finally {
      setSubmitting(false);
    }
  }

  const donorInfoInput = useMapping(() => {
    if (anonymous) return <>
    </>

    return <>
      <h5>The following information will be publicly visible and is not required.</h5>
      <label>Donor Name (Optional)</label>
      <input className="p-FieldLabel Label Label--empty" autoFocus={true} tabIndex={0} name="donorName" />
      <label>Message (Optional)</label>
      <input name="message" maxLength={128} />
    </>
  }, [ anonymous ]);

  const amountButtons = useMapping(() => {
    return donationAmounts.map(amount => {
      const onAmountClick = (e: any) => {
        console.log('selecting amount');
        e.stopPropagation();
        e.preventDefault();
        setSelectedAmount(amount);
        return false;
      }

      const isSelected = amount === selectedAmount;

      if (amount === 'other') {
        return <SimpleButton className={clsx(styles.amountButton, isSelected && styles.amountButtonSelected)} type='button'onClick={onAmountClick}>Other</SimpleButton>
      }

      return <SimpleButton className={clsx(styles.amountButton, isSelected && styles.amountButtonSelected)} type='button' onClick={onAmountClick}>
        <PriceLabel price={amount} />
      </SimpleButton>
    });
  }, [ selectedAmount ]);

  const otherAmountInput = useMapping(() => {
    if (selectedAmount !== 'other') return <></>
    return <div className={clsx(styles.amountLabel)}>
      Custom Amount: $ <input type="number" name="otherAmount" min="1" step="1" />
    </div>
  }, [ selectedAmount ]);

  return <div className={clsx(styles.form, styles.flex, styles.gap2, styles.spaceBetween, submitting && styles.disableInteractions)}>
    <h1>New Donation</h1>
    <form className={styles.fullWidth} action="javascript:void(0);" onSubmit={onSubmit}>
      {donorInfoInput}
      <ToggleInput enabled={anonymous} setEnabled={setAnonymous} label="Anonymous" />

      <label className={styles.amountLabel}>Amount</label>
      <div className={clsx(styles.amountButtons, styles.flex, styles.flexWrap)}>
        {amountButtons}
      </div>
      {otherAmountInput}

      <h5>The information below here private and never shown.</h5>
      <label>Card Number</label>
      <input type='number' placeholder='1234 1234 1234 1234' name="card.number" required />
      <label>Expiration</label>
      <div className={styles.exprInputWrapper}>
        <input name="expr.month" autoComplete="off" className={styles.exprInput} maxLength={2} pattern="[0-9]*" inputMode="numeric" placeholder="MM" type="text" data-pattern-validate />
        <input name="expr.year" autoComplete="off" className={styles.exprInput} maxLength={2} pattern="[0-9]*" inputMode="numeric" placeholder="YY" type="text" data-pattern-validate />
      </div>
      <label>CVC</label>
      <input inputMode="numeric" type='number' maxLength={3} placeholder='CVC' name="card.cvc" required />

      <label>Billing Info</label>

      <label>First Name</label>
      <input type='text' maxLength={64} name="billing.firstName" required />
      <label>Last Name</label>
      <input type='text' maxLength={64} name="billing.lastName" required />
      <label>Company Name</label>
      <input type='text' maxLength={64} name="billing.companyName" />

      <label>Street Address</label>
      <input type='text' minLength={1} maxLength={64} name="billing.addressLine1" required />
      <input type='text' maxLength={64} name="billing.addressLine2" />

      <label>City</label>
      <input type='text' maxLength={64} name="billing.city" required />

      <label>State</label>
      <input type='text' maxLength={64} name="billing.state" required />

      <label>ZIP Code</label>
      <input type='number' name="billing.postalCode" required />

      <label>Phone</label>
      <input type='text' maxLength={64} name="billing.phone" required />

      <label>Email Address</label>
      <input type='text' maxLength={64} name="billing.email" required />

      <div className={clsx(styles.flex, styles.center)}>
        <h6>A surcharge of 3.25% will be added to the transaction as part of the processing fee.</h6>
      </div>

      <div className={clsx(styles.flex, styles.center)} style={{ color: 'red' }}>
        <ErrorLabel error={error} />
      </div>
      <div className={clsx(styles.flex, styles.gap2, styles.spaceBetween, styles.reverseRow)}>
        <SimpleButton type='submit'>Submit</SimpleButton>
        <SimpleButton type="button" onClick={() => { onClose(); return false; }}>Cancel</SimpleButton>
      </div>
    </form>
  </div>
};